export const STYLE_VALUES = [
    'gender',
    'body_tags',
    'occupation',
    'referral',
    'style_icons',
    'goals_new',
    'events',
    'body_issues',
    'work_type',
    'holiday_type',
    'dress_size',
    'clothing_categories',
    'brands',
    'highlight',
    'colors_and_prints_objects',
    'colors_to_avoid',
    'patterns_to_avoid',
    'necklines_to_avoid',
    'clothing_preferences',
    'fit_top',
    'fit_bottom',
    'jeans',
    'fabrics',
    'budget_ranges',
    'comfort_zone',
    'heels',
    'jewelry',
    'what_matters'
];

export const CACHE_TIME_MS = 2 * 60 * 1000;
